import React, { useState, useMemo } from 'react'

import { agentImg, profile } from '../assets/images';

import { notification } from '../assets/sound';

import Question1 from './modules/Question1';

const Conversations = ({ agent }) => {

    const _questions1 = [
            {
                text: "Hi 👋",
                audio: false,
                delay: 1000,
                wait: 1000
            }, 
            {
                text: `I'm ${agent} from Injury Benefits Co.`,
                delay: 1000,
                wait: 2000
            },
            {
                text: "Want to find out if you're eligible for financial recovery or additional resources related to your accident? Tap Yes! 😃",
                delay: 1000,
                wait: 3000,
            },
            {
                text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12" onClick={() => { setAnswer1('Yes'); setPlay(true)}}>Yes</button>,
                delay: 1000,
                wait: 4000
            }
    ];

    const _questions2 = [
        {
            text: 'Great! Let me ask you a few quick questions to check your eligibility.',
            delay: 1000,
            wait: 1000
        },
    ];

    const _questions3 = [
        {
            text: '1. Have you been in a motor vehicle accident within the past 2 years?',
            delay: 1000,
            wait: 2000
        },
        {
            text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12"  onClick={() => setAnswer3('Yes')}>Yes</button>,
            delay: 1000,
            wait: 3000
        },
        {
            text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12" onClick={() => setAnswer3('No')}>No</button>,
            delay: 1000,
            wait: 4000
        }
    ];

    const _questions4 = [
        {
            text: '2. Was the accident your fault?',
            delay: 1000,
            wait: 1000
        },
        {
            text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12" onClick={() => setAnswer4('Yes')}>Yes</button>,
            delay: 1000,
            wait: 2000
        },
        {
            text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12" onClick={() => setAnswer4('No')}>No</button>,
            delay: 1000,
            wait: 3000
        }
    ];

    const _questions5 = [
        {
            text: '3. Were you injured in the accident?',
            delay: 1000,
            wait: 1000
        },
        {
            text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12" onClick={() => setAnswer5('Yes')}>Yes</button>,
            delay: 1000,
            wait: 2000
        },
        {
            text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12" onClick={() => setAnswer5('No')}>No</button>,
            delay: 1000,
            wait: 3000
        }
    ];

    const congratulations = [
        {
            text: '🎉 Congratulations! 🎁',
            dellay: 1000,
            waith: 1000,
        },
        { 
            text: 'Based on your answers, you are pre-qualified for significant financial recovery for your injuries or other damages related to your accident.',
            delay: 1000,
            wait: 2000,
        },
        {
            text: 'To confirm your eligibility and take the next step, tap the button below to CALL NOW. It only takes 2 minutes to finalize.',
            delay: 1000,
            wait: 3000
        },
        {
            text: <button  className="text-white font-bold bg-blue-500 rounded-full py-3 px-3" >+1 888-271-2145</button>,
            delay: 1000,
            wait: 4000
        }
    ];
    const sorry1 = [
        {
            text: 'Thank you for your response. Based on your answers, it seems you may not qualify for recovery at this time.',
            delay: 1000,
            wait: 1000
        },
        {
            text: 'If your circumstances change or you’d like additional assistance, feel free to reach out anytime!',
            delay: 1000,
            wait: 2000
        
        }   
    ];

    const sorry2 = [
        {
            text: 'Thank you for your response. Unfortunately, our resources are designed for specific cases.',
            delay: 1000,
            wait: 1000,
        },
        {
            text: 'If you believe this was a mistake or would like to learn more, feel free to reach out!',
            delay: 1000,
            wait: 2000
        }
    ];

    const ChatClosed = () => {

        return (

            <div className="pt-2">
                <div className="flex py-5 items-center">
                    <div className="flex-grow border-t border-gray-200"></div>
                    <span className="flex-shrink mx-4 text-gray-300 text-xs">Chat closed</span>
                    <div className="flex-grow border-t border-gray-200"></div>
                </div>
            </div>

        )

    }

    const audio = new Audio( notification );

    const [play, setPlay] = useState( false );

    const [questions1, setQuestions1] = useState(_questions1);
    const [questions2, setQuestions2] = useState(_questions2);
    const [questions3, setQuestions3] = useState(_questions3);
    const [questions4, setQuestions4] = useState(_questions4);
    const [questions5, setQuestions5] = useState(_questions5);

    const [answer1, setAnswer1] = useState('');
    const [answer3, setAnswer3] = useState('');
    const [answer4, setAnswer4] = useState('');
    const [answer5, setAnswer5] = useState('');

    
    useMemo(() => {

        if (answer1 === 'Yes') {

            setQuestions1([
                {
                    text: "Hi 👋",
                    delay: 100,
                    wait: 100
                }, 
                {
                    text: `I'm ${agent} from Injury Benefits Co.`,
                    delay: 100,
                    wait: 100
                },
                {
                    text: "Want to find out if you're eligible for financial recovery or additional resources related to your accident? Tap Yes! 😃",
                    delay: 100,
                    wait: 100,
                }
            ])
        }

        if (answer3 === 'Yes' || answer3 === 'No') {

            setQuestions3([
                {
                    text: '1. Have you been in a motor vehicle accident within the past 2 years?',
                    delay: 100,
                    wait: 100
                },
            ])

        }

        if (answer4 === 'Yes' || answer4 === 'No') {

            setQuestions4([
                {
                    text: '2. Was the accident your fault?',
                    delay: 100,
                    wait: 100
                }
            ])

        }

        if (answer5 === 'Yes' || answer5 === 'No') {

            setQuestions5([
                {
                    text: '3. Were you injured in the accident?',
                    delay: 100,
                    wait: 100
                },
            ])

        }

        if (play) {

            audio.play();

        }
    }, [answer1, answer3, answer4, answer5, play]);

    const UserProfile = ( {answer} ) => {
        return (
            <div className="flex items-start justify-end mt-2">
                <div className="ml-3 bg-blue-500 text-white p-3 rounded-lg shadow-sm w-fit">{ answer }</div>
                <img className="w-8 h-8 rounded-full ml-3" src={profile} />
            </div>
        )
    }

    const Agent = () => {

        return (

            <img src={ agentImg } className="w-8 h-8 rounded-full" />
        )

    }

    
    
    
    
    return (

        <div className="flex">
            <div className="items-end flex agent"><Agent /></div>
            <div>
                <Question1 questions={ questions1 } agent={"Martha"} />

                {
                    answer1 === 'Yes' ?

                        <div>
                            <UserProfile answer={ answer1 } />

                            <div>
                                <Question1 questions={ questions2 } audio={ audio } play={ play } />
                                <Question1 questions={ questions3 } audio={ audio } play={ play } />
                            </div>
                        </div>

                    : 

                        ''
                }

                {
                    answer3 === 'Yes' ?

                        <div>

                            <UserProfile answer={ answer3 } />

                            <Question1 questions={ questions4 } audio={ audio } play={ play } />

                        </div>
                

                    : answer3 === 'No' ?

                        <div>

                            <UserProfile answer={ answer3 } />
                            <Question1 questions={ sorry2 } audio={ audio } play={ play } />
                            <ChatClosed />

                        </div>

                    : ''

                }
                {
                    answer4 === 'Yes' ?

                        <div>

                            <UserProfile answer={ answer4 } />

                            <Question1 questions={ questions5 } audio={ audio } play={ play } />

                        </div>

                    : answer4 === 'No' ?

                        <div>

                            <UserProfile answer={ answer4 } />
                    
                            <Question1 questions={ questions5 } audio={ audio } play={ play } />

                            

                        </div>

                    : ''

                }
                {
                    answer5 === 'Yes' ?

                        <div>

                            <UserProfile answer={ answer5 } />
                    
                            <Question1 questions={ congratulations } audio={ audio } play={ play } />

                        </div>

                    : answer5 === 'No' ?

                        <div>

                            <UserProfile answer={ answer5 } />

                            <Question1 questions={ sorry1 } audio={ audio } play={ play } />

                        </div>

                    : ''



                }
            </div>
        </div>

    )

}




export default Conversations
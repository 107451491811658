import React from 'react'

const  Header = () => {
    return (
        <h2 className="font-bold text-xl sm:text-3xl mt-1">Just Announced: You Could Be Eligible for MAJOR Compensation if You've Been in a Motor Vehicle Accident.
        </h2>
    )
  
}

export default Header